<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.784" viewBox="0 0 20 19.784"
    class="h-5 w-5 inline-block ">
    <path id="person_FILL0_wght500_GRAD0_opsz48"
      d="M164.017,269.5a4.591,4.591,0,1,1,3.449-1.338A4.661,4.661,0,0,1,164.017,269.5Zm7.909,10.218H156.111a2.089,2.089,0,0,1-2.089-2.089v-.991a3.546,3.546,0,0,1,.611-2.085,3.926,3.926,0,0,1,1.577-1.327,23.109,23.109,0,0,1,3.977-1.391,16.161,16.161,0,0,1,3.83-.464,15.525,15.525,0,0,1,3.827.479,25.449,25.449,0,0,1,3.95,1.382,3.829,3.829,0,0,1,1.618,1.318,3.546,3.546,0,0,1,.61,2.087v.991a2.093,2.093,0,0,1-2.1,2.089Zm-15.814-2.089h15.814v-.962a1.654,1.654,0,0,0-.291-.927,1.839,1.839,0,0,0-.721-.653,16.129,16.129,0,0,0-3.54-1.274,15.947,15.947,0,0,0-3.359-.345,16.417,16.417,0,0,0-3.382.345,15.233,15.233,0,0,0-3.537,1.273,1.822,1.822,0,0,0-.984,1.581Zm7.906-10.218a2.609,2.609,0,1,0-1.93-.763A2.62,2.62,0,0,0,164.017,267.411ZM164.018,264.717ZM164.018,277.629Z"
      transform="translate(-154.022 -259.934)" fill="#fff" />


  </svg>
</template>

<script>
export default {
  props: ["currentRoute", "routeName"],
  mounted(){
  }

} 
</script>

<style></style>