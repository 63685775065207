// Import necessary modules from Vue and other dependencies
import { createApp, computed } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import i18n from './i18n'
import './registerServiceWorker'
  import router from './router'
import './index.css'
import { useLanguageStore } from './store/language'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
// Define your global toast options
const options = {
    // Close toast after 5000ms
    timeout: 5000,
    // Position of the toast
    position: 'top-center',
    // Allow dismissing the toast by clicking on it
    closeOnClick: true,
    // Display the close button on the toast
    showCloseButtonOnHover: true,
    // Pause the timer when the cursor is over the toast
    pauseOnHover: true,
    // Use your custom toast container class
    containerClassName: 'custom-toast-container',
    // Other global options...
};
/**
 * Initialize the Vue application
 * This function creates a new Vue application with the root component (App)
 * and applies the necessary plugins (Pinia for state management, router for routing, and i18n for internationalization)
 * @returns {Vue.App} The initialized Vue application
 */
function initializeApp() {
  const app = createApp(App)
  const pinia = createPinia()
  
  app.use(pinia)
  app.use(Toast, options)
  app.use(router)
  app.use(i18n)
  app.use(Toast, options)
  return app
}

/**
 * Initialize the locale for the application
 * This function retrieves the language store and initializes the locale
 * This is necessary for the i18n plugin to know which language to use when the application starts
 */
function initializeLocale() {
  const languageStore = useLanguageStore()
  languageStore.initLocale()
}

// Initialize the Vue application
const app = initializeApp()

// Initialize the locale
initializeLocale()

// Provide the isRtlLayout computed property to the entire application
// This allows any component in the application to inject and use isRtlLayout
// isRtlLayout is a computed property that determines whether the layout should be right-to-left based on the current language
const languageStore = useLanguageStore();
app.provide('isRtlLayout', computed(() => languageStore.isRtlLayout));

// Mount the Vue application to the DOM
app.mount('#app')

