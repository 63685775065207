// src/store/language.js

import { defineStore } from 'pinia';
import { LOCAL } from '@/constants';
export const useLanguageStore = defineStore('language', {
  state: () => ({
    selectedLocale: 'ar_iq', // Default language is arabic  
    isChanged: false
  }),
  getters: {
    isRtlLayout: (state) => state.selectedLocale == 'ar_iq',
    getIsChanged: (state) => state.isChanged
  },
  actions: {
    setLocale(locale) {
      if (this.selectedLocale !== locale) {
        this.isChanged = true
        // Set the selected locale in the store
        this.selectedLocale = locale;
        // Store the selected locale in local storage
        localStorage.setItem(LOCAL, locale);

        window.location.reload()
        this.isChanged = false
      }
    },
    initLocale() {
      // Retrieve the selected locale from local storage when the app starts
      const storedLocale = localStorage.getItem(LOCAL);
      if (storedLocale) {
        this.selectedLocale = storedLocale;
      } else {
        localStorage.setItem(LOCAL, 'ar_iq');
      }
    },
  },
});
