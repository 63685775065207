<template>
    <div class="h-screen md:mt-0 mt-6 flex items-center justify-center ">
          <div class="w-[1200px]   h-[60vh] relative overflow-hidden mx-3 rounded-md login-bg pb-2">
            <!-- Upper part with the image -->


            <div class=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                <div class="ml-6" v-if="!loading">
                    <done-svg class="" />
                    <strong class="text-md text-center text-gray-200">تم الإشتراك بنجاح</strong>
                </div>
                <div v-else class="flex flex-col justify-center items-center">
                    <div class="loader"></div>
                    <p class="text-center text-lg text-gray-100">الرجاء الانتظار</p>
                </div>
                <div class="my-2" v-show="!loading">

                    <button @click="$router.push('/')"
                        class="bg-[#F4DA2D] my-2 text-[#210B3E] lg:text-md text-sm  py-3 lg:px-9 md:px-0 px-6 w-56   rounded-lg hover:bg-[rgb(231,244,45)]">
                        {{ $t("back_home") }}
                    </button>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import DoneSvg from '@/components/svgs/DoneSvg.vue'
import { useAuthStore } from '@/store/auth'
import axios from 'axios';
import { mapActions } from 'pinia'
import { useToast } from 'vue-toastification'

export default {

    components: {
        DoneSvg,
       
    },
    data() {
        return {

            success: false,
            message: "",
            toast:useToast(),
            loading:false
        }
    },
    computed: {
    },

    methods: {
        ...mapActions(useAuthStore,['login','setData'])
    },
   
async created(){
  const clickID = localStorage.getItem('click_id');
  if (clickID) {
    try {
// eslint-disable-next-line 
      const response = await axios.get(`https://geo-tracker.smadex.com/hyperad/tracking/action/subscription?click_id=${clickID}&tracker=systech`);
      // Handle successful response
      localStorage.removeItem('click_id')
    } catch (error) {
      // Handle error
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Response error:', error.response.status, error.response.data);
      } else if (error.request) {
        // Request was made but no response received
        console.error('Request error:', error.request);
      } else {
        // Something else caused the error
        console.error('Error:', error.message);
      }
    }
    localStorage.removeItem('click_id')

  }
   setTimeout(()=> this.$router.push('/'),3000)

}

}
</script>

<style scoped>
.auth-slider {
    width: 100%;
    height: 335px;
}

@media only screen and (max-width: 960px) {
    .auth-slider {
        width: 100%;
        height: 280px;
    }
}
.loader {
  width: 50px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     #f03355 96%,#0000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
     #f03355 96%,#0000) 0 50%/80% 20% repeat-x;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}
</style>