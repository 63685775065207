<template>
  <div class="relative min-h-screen">
    <div id="content-wrap">
      <div class="">
        <nav-bar class="navbar" :style="navbarBackgroundImage" />
        <div class="md:hidden flex justify-center relative" v-show="$route.name!='home'"> <router-link to="/" class="absolute z-50"><img src="@/assets/yawyaw.png" alt="Logo" class="h-20   w-20  " /></router-link>
        </div>
      </div>
      <div class="content">
        <reload-page/>
        <router-view :key="$route.fullPath" />
      </div>
      <section>

      </section>
    </div>
    <mobile-menu class="mobile-menu" :style="navbarBackgroundImage" />
    <footer class=" absolute bottom-0 left-0 w-full h-20  z-50 hidden md:block ">
      <div class="flex flex-col text-center">
        <p class="text-gray-400">YAW-YAW GAMES 2024</p>
        <p class="text-gray-400 text-xs">POWERED BY SYSTECH</p>
      </div>
    </footer>

  </div>
</template>
  
<script>

import NavBar from "../partials/desktop/NavBar.vue"
import MobileMenu from '../partials/mobile/MobileMenu.vue';
import ReloadPage from '../partials/ReloadPage.vue';
export default {
  name: "DefaultLayout",
  components: {
    NavBar,
    MobileMenu,
    ReloadPage
  },
  data() {
    return {
      navbarBackgroundImage: "linear-gradient(119deg, #26084d 0%, #57214f 50%, #102e5f 100%) 0% 0% no-repeat padding-box",

    };
  },

  computed: {
    layout() {
      return this.$route.meta.layout || "default";
    },

  },
  methods: {
    getElements() {
      return {
        navbar: this.$el.querySelector(".navbar"),
        content: this.$el.querySelector(".content")
      }
    },
    isContentVisible(content, navbar) {
      const contentBottom = content.offsetTop + content.offsetHeight;
      return window.scrollY < contentBottom - navbar.offsetHeight;
    },
    changeNavbarBackground() {
      const { navbar, content } = this.getElements();


      this.navbarBackgroundColor = this.isContentVisible(content, navbar)
        ? "transparent"
        : "rgba(51, 51, 51, 1)";
    },
  },
  mounted() {

    window.addEventListener("scroll", this.changeNavbarBackground);
    this.changeNavbarBackground();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.changeNavbarBackground);
  },

};
</script>
  
<style></style>
<style scoped>
#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 6rem;
  /* Footer height */
}

@media only screen and (max-width: 960px) {
  #content-wrap {
    padding-bottom: 3rem;
    /* Footer height */
  }
}

*:focus,*:active {
  outline: none;
}
</style>
  